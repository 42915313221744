import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles({
    bodyContainer: {
        padding: '2px 24px',
        overflowY: 'auto'
    }
})

export {useStyles}
