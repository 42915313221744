export enum MessageType {
    info = 'info',
    warning = 'warning',
    success = 'confirmation',
    error = 'error'
}

export class FetchDataError extends Error {
    title: string
    subTitle: string
    messageType: MessageType

    constructor(title: string, subTitle: string, messageType: MessageType) {
        super(title)

        this.title = title
        this.subTitle = subTitle
        this.messageType = messageType
        Object.setPrototypeOf(this, new.target.prototype) // restore prototype chain
    }
}