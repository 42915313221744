import SaveFile from 'trello-shared-resources/dist/modules/file/SaveFile'
import Board from 'trello-shared-resources/dist/types/Board'
import ExportModelAdaptor from './ExportModelAdaptor'
import {ReportData} from '../../types/ReportProperties'

import * as Papa from 'papaparse'
import moment from 'moment'

class CSVDownload {
    fileType: string

    constructor() {
        this.fileType = 'text/csv;charset=utf-8;'
    }

    download(data: Array<ReportData>, board:Board) {
        const exportData = data.map((dataItem: ReportData) => new ExportModelAdaptor(dataItem))
        const csvOutput = Papa.unparse(exportData)
        const saveFile = new SaveFile(this.fileType)
        const currentDate = moment().format('MM/DD/YYYY')
        const fileName = `${board.name} ${currentDate}.csv`
        saveFile.saveDataToFile(fileName, csvOutput)
    }
}

export default CSVDownload