import React, {useEffect, useState} from 'react'
import {useStyles} from './ReportDialogStyles'
import {Box, Grid, Typography} from '@material-ui/core'
import SecondaryButton from './SecondaryButton'
import ReportFilter from './ReportFilter'
import Spinner from '@atlaskit/spinner'
import {LogEntry, ReportData, ReportDataMessage, ReportDialogProperties,} from '../../types/ReportProperties'
import {TrackActionEvent} from 'trello-shared-resources/dist'
import {SelectOptionValue} from "../../types/LogProperties"
import ReportDisplayMessages from "./ReportDisplayMessages"
import GroupDataTable from "./table/GroupDataTable"
import LogWorkTimeModal from "../log/LogWorkTimeModal";

/**
 * Time Tracking Reports screen inside the main dialog
 */
const ReportDialog = (props: ReportDialogProperties) => {
    const classes = useStyles()
    const licenseDetails = props.licenseDetails

    const [isLoading, setIsLoading] = useState(false)
    const [logEntries, setLogEntries] = useState<LogEntry[]>([])
    const [downloadData, setDownloadData] = useState<ReportData[] | undefined>(undefined)
    const [reportDataMessage, setReportDataMessage] = useState<ReportDataMessage | undefined>(undefined)
    const [board, setBoard] = useState({members: []})
    const [isCreateWorklogModalOpen, setIsCreateWorklogModalOpen] = useState(false)
    const trelloIframeContext = props.licenseDetails.trelloIframeContext
    const [groupBy, setGroupBy] = useState<SelectOptionValue>({label: 'Card', value: 'cards'})
    const [actionPerformed, setActionPerformed] = useState(0)
    const [editedRow, setEditedRow] = useState<string | undefined>()

    useEffect(() => {
        trelloIframeContext.board('all')
            .then((board: any) => {
                setBoard(board)
            })
    }, [trelloIframeContext, setBoard])

    const incrementActionPerformed = () => {
        setActionPerformed(actionPerformed + 1)
    }

    const clickOnLogTimeButton = () => {
        const trelloContext = trelloIframeContext.getContext()
        setIsCreateWorklogModalOpen(true)
        TrackActionEvent('Button Click', trelloContext, {
            board_id: trelloContext.board,
            button: 'log_time',
            action: 'open'
        })
    }

    return (
        <Grid container className={classes.container}>
            <Grid container spacing={0}>
                <Grid item xs="auto">
                    <Typography className={classes.appTitle}>
                        Time Tracking Reports
                    </Typography>
                </Grid>
                <Grid item xs>
                    <Grid container direction="row" justifyContent="flex-end">
                        <SecondaryButton onClick={clickOnLogTimeButton}/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="flex-start">
                <Grid item xs>
                    <ReportFilter licenseDetails={licenseDetails} isLoading={isLoading} setIsLoading={setIsLoading}
                                  setLogEntries={setLogEntries} setDownloadData={setDownloadData}
                                  members={board.members}
                                  actionPerformed={actionPerformed}
                                  setReportDataMessage={setReportDataMessage}
                                  groupBy={groupBy}
                                  setGroupBy={setGroupBy}
                                  board={board}
                                  downloadData={downloadData}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={0}>
                <Grid item xs={12} className={classes.reportContainer}>
                    <Box className={classes.messageContainer}>
                        <ReportDisplayMessages reportDataMessage={reportDataMessage}
                                               setReportDataMessage={setReportDataMessage}/>
                    </Box>
                    {!isLoading &&
                        <GroupDataTable logEntries={logEntries} reportDataMessage={reportDataMessage}
                                        setReportDataMessage={setReportDataMessage}
                                        isCardType={groupBy.value === 'cards'}
                                        editedRow={editedRow}
                                        setEditedRow={setEditedRow}
                                        incrementActionPerformed={incrementActionPerformed}
                                        downloadData={downloadData} licenseDetails={licenseDetails}
                                        setIsLoading={setIsLoading}/>
                    }
                </Grid>

                {isLoading &&
                    <Grid item xs={12} className={classes.timeTrackingSpinner}>
                        <Spinner size={200}/>
                    </Grid>
                }
            </Grid>
            <LogWorkTimeModal isCreateWorklogModalOpen={isCreateWorklogModalOpen} licenseDetails={props.licenseDetails}
                              incrementActionPerformed={incrementActionPerformed}
                              setIsWorklogModalOpen={setIsCreateWorklogModalOpen} setReportDataMessage={setReportDataMessage}/>
        </Grid>
    )
}

export default ReportDialog