import React, { useEffect, useState } from 'react'
import Select from '@atlaskit/select'
import { ErrorMessage, Field } from '@atlaskit/form'
import { useStyles } from './DurationPickerStyles'
import { Grid } from '@material-ui/core'
import { GroupingPickerProperties, ttReportFilterKey } from '../../types/ReportProperties'
import {SelectOptionValue} from "../../types/LogProperties"

/**
 * Select the grouping criteria for the report output.
 */
const GroupingPicker = (props: GroupingPickerProperties) => {
    const classes = useStyles()
    const {setGroupBy, errors, licenseDetails} = props
    const [defaultValue, setDefaultValue] = useState<SelectOptionValue>({label: 'Card', value: 'cards'})

    useEffect(() => {
        const trelloIframeContext = licenseDetails.trelloIframeContext
        trelloIframeContext.get('member', 'private', ttReportFilterKey)
            .then(function (ttReportFilter: any) {
                if (ttReportFilter) {
                    setGroupBy(ttReportFilter.groupBy)
                    setDefaultValue(ttReportFilter.groupBy)
                }
            })
    }, [licenseDetails.trelloIframeContext, setGroupBy])

    const selectOnChange = (selectedValue: SelectOptionValue | null) => {
        selectedValue = selectedValue || {value: '', label: ''}
        setGroupBy(selectedValue)
        setDefaultValue(selectedValue)
    }
    const isClearable = defaultValue.value !== ''

    return (
        <Grid item xs className={classes.datePickerContainer}>
            <Field name="groupBy" label="Group by" defaultValue="" isRequired>
                {({fieldProps, error}) => (
                    <>
                        <Select
                            options={[
                                {label: 'Card', value: 'cards'},
                                {label: 'Member', value: 'members'},
                            ]}
                            onChange={selectOnChange}
                            value={defaultValue}
                            isClearable={isClearable}
                        />
                        {errors.hasOwnProperty('groupBy') &&
                        <div>
                            <ErrorMessage>{error}</ErrorMessage>
                        </div>
                        }
                    </>
                )}
            </Field>
        </Grid>
    )
}
export default GroupingPicker