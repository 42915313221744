import React from 'react'
import Modal, {ModalTransition} from '@atlaskit/modal-dialog'
import {LogWorkTimeModalProperties} from '../../types/LogWorkTimeModalProperties'
import LogWorkTime from './LogWorkTime'
import {Box} from "@material-ui/core"
import {useStyles} from "./LogWorkTimeModalStyles"

const LogWorkTimeModal = (props: LogWorkTimeModalProperties) => {

    const classes = useStyles()
    const {isCreateWorklogModalOpen} = props

    return <ModalTransition>
        {isCreateWorklogModalOpen && (
            <Modal width={'large'}>
                <Box className={classes.bodyContainer}>
                    <LogWorkTime {...props}/>
                </Box>
            </Modal>
        )}
    </ModalTransition>
}

export default LogWorkTimeModal
