import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
        container: {
            padding: '31px 48px 31px 48px',
        },
        appTitle: {
            height: '29px',
            color: '#172B4E',
            fontFamily: 'SF Pro Display Bold',
            fontSize: '24px',
            letterSpacing: 0,
            lineHeight: '29px',
            marginBottom: '40px'
        },
        downloadReportButton: {
            marginTop: '40px',
        },
        timeTrackingSpinner: {
            marginTop: '50px',
            textAlign: 'center'
        },
        messageContainer: {
            marginBottom: 32
        },
        reportContainer: {
            marginTop: 32,
            textAlign: 'start',
        }
    }
)

export { useStyles }
