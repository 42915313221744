import React, { useEffect } from 'react'
import { LicenseDetailsContext, LicenseDetailsProvider, PageView } from 'trello-shared-resources/dist/index'
import { Skeleton } from 'antd'
import Report from './Report'

/**
 * The top level Report 'view'. This wraps the report functionality in the require licensing provider.
 */
const ReportLicenseConsumer = (props: { selectedMenuItem: string }) => {

    useEffect(() => {
        PageView('Report')
    }, [])

    return <LicenseDetailsProvider>
        <LicenseDetailsContext.Consumer>
            {(licenseDetails: any) => {
                if (licenseDetails.isLoading) {
                    return <Skeleton active={true}/>
                }
                return <Report licenseDetails={licenseDetails} selectedMenuItem={props.selectedMenuItem}/>
            }}
        </LicenseDetailsContext.Consumer>
    </LicenseDetailsProvider>
}

export default ReportLicenseConsumer