import Tooltip from "@atlaskit/tooltip"
import React from "react"
import {useStyles} from "./TableCellTooltipStyles"
import {TableCellTooltipProperties} from "../../../types/TableCellTooltipProperties"
import {Box} from "@material-ui/core"

const TableCellTooltip = (props: TableCellTooltipProperties) => {

    const classes = useStyles()

    const {tooltip, cellValue} = props

    return <Box data-tag="allowRowEvents" className={classes.tooltipContainer}>
        <Tooltip content={tooltip || cellValue} data-tag="allowRowEvents">
            <Box data-tag="allowRowEvents" className={classes.tooltip}>
                {cellValue}
            </Box>
        </Tooltip>
    </Box>
}

export default TableCellTooltip