import React, {useCallback, useEffect, useState} from 'react'
import {Grid} from '@material-ui/core'
import Button from '@atlaskit/button'
import Form from '@atlaskit/form'
import {useStyles} from './ReportFilterStyles'
import {LogTimeData, ReportFilterProperties, ttReportFilterKey} from '../../types/ReportProperties'
import DurationPicker from './DurationPicker'
import GroupingPicker from './GroupingPicker'
import {getAllTimeEntries} from "../../modules/Persistence"
import ReportDataGroupByCard from "../../modules/grouping/ReportDataGroupByCard"
import ReportDataGroupByMember from "../../modules/grouping/ReportDataGroupByMember"
import {TrackActionEvent} from "trello-shared-resources/dist"
import DownloadPicker from "./DownloadPicker"

/**
 * A banner that contains filter tools that allow the user to filter the time tracking reports.
 * The "Find Logs" button that triggers report generation will not need its own component. I've
 * justified this with the understanding that it is a completely static element with no need to manage it's own state.
 */
const ReportFilter = (props: ReportFilterProperties) => {
    const classes = useStyles()

    const today = new Date()
    const firstDayCurrentMonth = new Date(today.getFullYear(), today.getMonth(), 1)

    const {
        members, licenseDetails, groupBy, setGroupBy, setReportDataMessage, setLogEntries,
        setDownloadData, setIsLoading
    } = props

    const [dateFrom, setDateFrom] = useState(firstDayCurrentMonth.toISOString())
    const [dateTo, setDateTo] = useState(today.toISOString())
    const [errors, setErrors] = useState({})
    const [otherMembers, setOtherMembers] = useState([])
    const [unsentGroupBy, setUnsentGroupBy] = useState(groupBy)

    /**
     * Saving preferred search
     */
    const savePreferredSearch = useCallback(() => {
        const ttReportFilterObject = {groupBy: unsentGroupBy}
        const trelloIframeContext = props.licenseDetails.trelloIframeContext
        trelloIframeContext.set('member', 'private', ttReportFilterKey, ttReportFilterObject)
    }, [props.licenseDetails.trelloIframeContext, unsentGroupBy])

    const validateOnSubmit = () => {
        let newErrors = {}
        if (!unsentGroupBy.value) {
            newErrors = {
                groupBy: 'This field is required',
            }
        }
        if (!dateFrom) {
            newErrors = {
                ...newErrors,
                dateFrom: 'Select the date in order to view the logs',
            }
        }
        if (!dateTo) {
            newErrors = {
                ...newErrors,
                dateTo: 'Select the date in order to view the logs',
            }
        }
        newErrors = {
            ...newErrors,
            ...validateCorrectDateOrder(dateFrom, dateTo)
        }
        setErrors(newErrors)
        return newErrors
    }

    const onFindAction = useCallback(() => {
        const errors = validateOnSubmit()
        if (errors && Object.keys(errors).length > 0) {
            return
        }
        setIsLoading(true)
        savePreferredSearch()
        setGroupBy(unsentGroupBy)

        getAllTimeEntries(licenseDetails, dateFrom, dateTo, members, otherMembers, setOtherMembers)
            .then((data: LogTimeData) => {
                if (data.reportDataMessage) {
                    setReportDataMessage(data.reportDataMessage)
                }

                const filteredData = data.reportData

                // Group the data
                const groupStrategy = unsentGroupBy.value === 'cards' ?
                    new ReportDataGroupByCard() : new ReportDataGroupByMember()

                setIsLoading(false)
                setLogEntries(groupStrategy.group(filteredData))
                setDownloadData(filteredData)

                const trelloContextInfo = licenseDetails.trelloIframeContext.getContext()
                TrackActionEvent('Find Worklogs', trelloContextInfo, {
                    board_id: trelloContextInfo.board,
                    date_start: dateFrom,
                    date_end: dateTo,
                    grouping: unsentGroupBy.value
                })
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [licenseDetails, licenseDetails.trelloIframeContext.getContext(),
        setReportDataMessage, setLogEntries, setDownloadData, setIsLoading, validateOnSubmit, savePreferredSearch])

    const onChangeDateFrom = (value: string) => {
        setDateFrom(value)
        validateCorrectDateOrder(value, dateTo)
    }

    const onChangeDateTo = (value: string) => {
        setDateTo(value)
        validateCorrectDateOrder(dateFrom, value)
    }

    const validateCorrectDateOrder = (from: string, to: string) => {
        let newErrors = {}
        if (from && to) {
            const d1 = Date.parse(from)
            const d2 = Date.parse(to)
            if (d1 > d2) {
                newErrors = {
                    dateFrom: 'The start date must be before, or the same, as the end date'
                }
            }
        }
        setErrors(newErrors)
        return newErrors
    }

    useEffect(() => {
        onFindAction()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.actionPerformed])

    return (
        <Form onSubmit={() => validateOnSubmit()}>
            {({formProps}) => (
                <form {...formProps}>
                    <Grid container spacing={2} alignItems="flex-start" justifyContent="space-between">

                        <DurationPicker value={dateFrom} label={'From'} errors={errors} paramName={'dateFrom'}
                                        onChangeDate={onChangeDateFrom}/>
                        <DurationPicker value={dateTo} label={'To'} errors={errors} paramName={'dateTo'}
                                        onChangeDate={onChangeDateTo}/>
                        <GroupingPicker licenseDetails={props.licenseDetails} setGroupBy={setUnsentGroupBy}
                                        errors={errors}/>
                        <Grid item xs className={classes.viewTimeLogButton}>
                            <Button
                                type={'submit'}
                                appearance={'primary'}
                                isDisabled={props.isLoading}
                                onClick={onFindAction}
                            >
                                View Time Logs
                            </Button>
                        </Grid>
                        <Grid item xs className={[classes.viewTimeLogButton, classes.downloadReportButton].join(' ')}>
                            <DownloadPicker {...props}/>
                        </Grid>
                    </Grid>
                </form>
            )}
        </Form>
    )
}
export default ReportFilter