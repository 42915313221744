import Card from 'trello-shared-resources/dist/types/Card'
import Member from 'trello-shared-resources/dist/types/Member'
import {MessageType} from '../error/FetchDataError'
import React from 'react'
import {ExpanderComponentProps} from 'react-data-table-component'
import {SelectOptionValue} from './LogProperties'

export interface ReportFilterProperties {
    licenseDetails: any
    isLoading: boolean
    members: any
    groupBy: SelectOptionValue
    actionPerformed: number
    board: any
    downloadData: Array<ReportData> | undefined

    setGroupBy(value: SelectOptionValue): void
    setIsLoading(value: boolean): void
    setLogEntries(value: any): void
    setDownloadData(value: any): void
    setReportDataMessage(value: ReportDataMessage | undefined): void
}

export interface ReportDialogProperties {
    licenseDetails: any,
}

export const ttReportFilterKey = 'ttReportFilterKey'

export interface GroupingPickerProperties {
    setGroupBy(value: any): void

    licenseDetails: any,
    errors: any
}


export interface DownloadPickerProperties {
    licenseDetails: any,
    board: any,
    downloadData: undefined | Array<ReportData>
    setReportDataMessage(value: ReportDataMessage | undefined): void
}

export interface DurationPickerProperties {
    value: string
    label: string
    errors: any
    paramName: string
    onChangeDate(value: string): void
}

// --== Report Display Properties ==--
export interface ReportDisplayProperties extends ReportDisplayMessageProperties{
    logEntries: Array<LogEntry>
    licenseDetails: any
    isCardType: boolean
    downloadData: undefined | Array<ReportData>

    incrementActionPerformed(): void
    setIsLoading(value: boolean): void

    editedRow: string | undefined
    setEditedRow(editedRowKey: string | undefined): void
}

export interface ReportDisplayMessageProperties {
    reportDataMessage?: ReportDataMessage,
    setReportDataMessage: (reportDataMessage: ReportDataMessage | undefined) => void
}

export interface ReportDataMessage {
    title: string
    subTitle: React.ReactNode | string
    messageType: MessageType
    closable: boolean
}

export interface LogEntry {
    headerKey: string,
    headerValue: string,
    entityType: 'card' | 'member',
    noOfHoursWorked: number,
    noOfBillableHoursWorked: number,
    noOfWorkLogs: number,
    tableData: Array<ReportData>
}

export interface LogTimeData {
    reportData: Array<ReportData>
    reportDataMessage?: ReportDataMessage
}

export interface LogWorkTimeData {
    key?: string,
    date?: string,
    member?: Member,
    card?: Card,
    listId: string
    loggedHours?: number
    workDescription?: string
    endDate?: string
    startTime?: number
    rawStartDate?: number
    endTime?: number
    rawEndDate?: number
    userTimezone?: string,
    billable: boolean
}

export interface ReportData {
    key: string,
    date: string,
    member?: Member,
    card?: Card,
    listId: string
    loggedHours: number
    workDescription?: string
    endDate?: string
    startTime?: number
    rawStartDate?: number
    endTime?: number
    rawEndDate?: number
    userTimezone?: string,
    billable: boolean
}

export interface NestedDataTableProperties extends ExpanderComponentProps<any>, ReportDisplayMessageProperties {
    licenseDetails: any
    downloadData: undefined | Array<ReportData>
    editedRow: string | undefined
    incrementActionPerformed: () => void
    setIsLoading: (value: boolean) => void
    parentWidth: number
}