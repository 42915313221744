import Label from "trello-shared-resources/dist/types/Label";

/**
 * Converts the labels present in a card to an output string for the report download.
 * 
 * @param labels The labels that are present in a card
 */
export function labelsTransformer(labels: Array<Label> | undefined) {
    if (!labels || labels.length === 0) {
        return 'No label'
    }

    const labelOutputs = labels.map((label) => {
        const labelName = label.name ? `${label.name} ` : ''
        return `${labelName}(${label.color})`
    })

    return labelOutputs.join(', ')
}