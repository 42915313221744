import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
    tooltipContainer: {
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    tooltip: {
        textOverflow: 'ellipsis',
        overflow: 'hidden'
    },
})

export { useStyles }
