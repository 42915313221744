import React, { useEffect } from 'react'
import { LicenseDetailsContext, LicenseDetailsProvider, PageView } from 'trello-shared-resources/dist/index'
import { Skeleton } from 'antd'
import CardBackSection from './CardBackSection'
import { useStyles } from './CardBackSectionStyles'

/**
 * The top level for the Card Back Section 'view'. This wraps the iframe and its functionalities in the require licensing provider.
 */
const CardBackSectionLicenseConsumer = () => {
    const classes = useStyles()

    useEffect(() => {
        PageView('Card Back Section')
    }, [])

    return <LicenseDetailsProvider>
        <LicenseDetailsContext.Consumer>
            {(licenseDetails: any) => {
                if (licenseDetails.isLoading) {
                    return <div className={classes.skeletonAppContainer}><Skeleton active={true}/></div>
                }
                return <div className={classes.appContainer} id="contentCardBackSection"><CardBackSection
                    licenseDetails={licenseDetails}/></div>
            }}
        </LicenseDetailsContext.Consumer>
    </LicenseDetailsProvider>
}

export default CardBackSectionLicenseConsumer