import React, {useEffect, useRef, useState} from 'react'
import {LogEntry, ReportDisplayProperties} from '../../../types/ReportProperties'
import DataTable from 'react-data-table-component'
import NestedDataTable from './NestedDataTable'
import {convertSecondsToWorklogDuration, getTextFromNo} from '../../../modules/Utils'
import TableCellTooltip from './TableCellTooltip'
import {Box} from '@material-ui/core'
import {groupDataTableCustomStyles, useStyles} from './GroupDataTableStyles'

/**
 * Table where data is shown
 */
const GroupDataTable = (props: ReportDisplayProperties) => {
    const {isCardType} = props
    const [selectedRow, setSelectedRow] = useState('')

    const dataTableRef = useRef<HTMLDivElement>(null)

    const columns = [
        {
            name: isCardType ? 'Card Name' : 'Member Name',
            selector: (row: any) => row.headerValue,
            cell: (row: any) => <TableCellTooltip cellValue={row.headerValue}/>,
            wrap: true,
            sortable: true,
            style: {
                fontFamily: 'SF Pro Display Bold',
            },
            maxWidth: dataTableRef.current?.offsetWidth ? `${Math.max((dataTableRef.current.offsetWidth - 148) / 6, 100)}px` : '120px',
            minWidth: dataTableRef.current?.offsetWidth ? `${Math.max((dataTableRef.current.offsetWidth - 148) / 6, 100)}px` : '120px'
        },
        {
            name: isCardType ? 'Total Members' : 'Total Cards',
            selector: (row: any) => isCardType ? getTextFromNo(row.noOfDistinctMembers, 'Member') : getTextFromNo(row.noOfDistinctCards, 'Card'),
            maxWidth: dataTableRef.current?.offsetWidth ? `${Math.max((dataTableRef.current.offsetWidth - 148) / 6, 100)}px` : '120px',
            minWidth: dataTableRef.current?.offsetWidth ? `${Math.max((dataTableRef.current.offsetWidth - 148) / 6, 100)}px` : '120px'
        },
        {
            name: 'Total Time Logs',
            selector: (row: any) => getTextFromNo(row.noOfWorkLogs, 'time log'),
            maxWidth: dataTableRef.current?.offsetWidth ? `${Math.max((dataTableRef.current.offsetWidth - 148) / 6, 100)}px` : '120px',
            minWidth: dataTableRef.current?.offsetWidth ? `${Math.max((dataTableRef.current.offsetWidth - 148) / 6, 100)}px` : '120px'
        },
        {
            name: 'Total Logged Time',
            selector: (row: any) => convertSecondsToWorklogDuration(row.noOfHoursWorked),
            maxWidth: dataTableRef.current?.offsetWidth ? `${Math.max((dataTableRef.current.offsetWidth - 148) / 6, 100)}px` : '120px',
            minWidth: dataTableRef.current?.offsetWidth ? `${Math.max((dataTableRef.current.offsetWidth - 148) / 6, 100)}px` : '120px'
        },
        {
            name: 'Total Descriptions',
            selector: (row: any) => getTextFromNo(row.noOfDescriptions, 'Note'),
            maxWidth: dataTableRef.current?.offsetWidth ? `${Math.max((dataTableRef.current.offsetWidth - 148) / 6, 100)}px` : '120px',
            minWidth: dataTableRef.current?.offsetWidth ? `${Math.max((dataTableRef.current.offsetWidth - 148) / 6, 100)}px` : '120px'
        },
        {
            name: 'Total Billable Time',
            selector: (row: any) => convertSecondsToWorklogDuration(row.noOfBillableHoursWorked) || '0h 0m',
            maxWidth: dataTableRef.current?.offsetWidth ? `${Math.max((dataTableRef.current.offsetWidth - 148) / 6, 100)}px` : '120px',
            minWidth: dataTableRef.current?.offsetWidth ? `${Math.max((dataTableRef.current.offsetWidth - 148) / 6, 100)}px` : '120px'
        },
        // icons column
        {
            width: '100px',
        },
    ]

    // change the background color of the selected row to light blue
    const conditionalRowStyles = [
        {
            when: (row: LogEntry) => row.headerKey === selectedRow,
            style: {
                backgroundColor: '#ddecff'
            },
        }
    ]

    /**
     * Expand row in table after edit
     * @param row
     */
    const expandEditedRow = (row: LogEntry) => {
        return row.tableData.some(entry => entry.key === props.editedRow)
    }

    const handleSelectedRowChange = (expanded: boolean, row: LogEntry) => {
        expanded ? setSelectedRow(row.headerKey) : setSelectedRow('')
        props.setEditedRow(undefined)
    }

    useEffect(() => {
    }, [dataTableRef])

    return (<div ref={dataTableRef}>
            <DataTable
                columns={columns}
                data={props.logEntries}
                pagination={true}
                highlightOnHover={true}
                expandOnRowClicked={true}
                defaultSortFieldId={1}
                conditionalRowStyles={conditionalRowStyles}
                expandableRowExpanded={expandEditedRow}
                onRowExpandToggled={handleSelectedRowChange}
                paginationPerPage={7}
                theme="atlasKitTheme"
                customStyles={groupDataTableCustomStyles}
                paginationComponentOptions={{
                    noRowsPerPage: true,
                }}
                expandableRows
                // @ts-ignore
                expandableRowsComponent={NestedDataTable}
                expandableRowsComponentProps={{...props, parentWidth: dataTableRef.current?.offsetWidth}}
                persistTableHead
                noDataComponent={<NoData/>}
            />
        </div>
    )
}
export default GroupDataTable

export const NoData = () => {
    const classes = useStyles()
    return <Box className={classes.noDataRow}>
        There are no time logs for this time period, please change the dates selected, or create a new time log using
        the "Log time" button above.
    </Box>

}
