import React from 'react'
import {ReportDisplayMessageProperties} from '../../types/ReportProperties'
import SectionMessage from '@atlaskit/section-message'
import {Box} from "@material-ui/core"
import CrossIcon from "@atlaskit/icon/glyph/cross"
import {useStyles} from "./ReportDisplayMessagesStyle"

/**
 * Messages to show in case there is no data to display
 */
const ReportDisplayMessages = (props: ReportDisplayMessageProperties) => {
    const {reportDataMessage, setReportDataMessage} = props

    const classes = useStyles()

    return (
        <>
            {reportDataMessage && <SectionMessage
                title={reportDataMessage.title}
                appearance={reportDataMessage.messageType}
            >
                {reportDataMessage.closable && <Box className={classes.closeResultMessageContainer}
                                                    onClick={() => setReportDataMessage(undefined)}>
                    <CrossIcon label='close' size='small'/>
                </Box>}
                {typeof reportDataMessage.subTitle === "string" ? <div dangerouslySetInnerHTML={{__html: reportDataMessage.subTitle}}/> : reportDataMessage.subTitle}
            </SectionMessage>}
        </>
    )
}
export default ReportDisplayMessages