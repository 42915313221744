import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles({
        formContainer: {
            padding: '2px 24px'
        },
        messageContainer: {
            textAlign: 'start',
            padding: 24,
            paddingTop: 0
        },
        reportLink: {
            float: 'right',
            marginRight: 24,
            marginBottom: 24,
            color: '#0052CC',
            fontFamily: 'SF Pro Text Bold',
            fontSize: 14,
            letterSpacing: 0,
            lineHeight: '18px',
            borderBottom: '1px solid #0052CC',
            '&:hover': {
                color: '#0052CC',
                textDecoration: 'none'
            }
        }
    }
)

export {useStyles}
