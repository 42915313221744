import React from 'react'
import { DatePicker } from '@atlaskit/datetime-picker'
import { ErrorMessage, Field } from '@atlaskit/form'
import { getDateFormatString, getUserLocale } from '../../modules/Utils'
import { useStyles } from './DurationPickerStyles'
import { Grid } from '@material-ui/core'
import { DurationPickerProperties } from '../../types/ReportProperties'

const DurationPicker = (props: DurationPickerProperties) => {
    const classes = useStyles()
    const DATA_FORMAT = getDateFormatString()
    const {value, label, errors, paramName, onChangeDate} = props

    return (
        <Grid item xs className={classes.datePickerContainer}>
            <Field name={paramName} label={label} defaultValue="" isRequired>
                {() => (
                    <>
                        <DatePicker
                            onChange={onChangeDate}
                            value={value}
                            placeholder={DATA_FORMAT}
                            locale={getUserLocale()}
                            dateFormat={DATA_FORMAT}
                        />
                        {errors.hasOwnProperty(paramName) &&
                        <div>
                            <ErrorMessage>{errors[paramName]}</ErrorMessage>
                        </div>
                        }
                    </>
                )}
            </Field>
        </Grid>
    )
}

export default DurationPicker