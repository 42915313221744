import React from 'react'
import Modal, {ModalBody, ModalTransition} from '@atlaskit/modal-dialog'
import {Box, Grid, Typography} from "@material-ui/core"
import Button from "@atlaskit/button"
import {CancelModalProperties} from "../../types/CancelModalProperties"
import {useStyles} from "./CancelModalStyles"
import CrossIcon from "@atlaskit/icon/glyph/cross"

const CancelModal = (props: CancelModalProperties) => {
    const classes = useStyles()
    const {isCancelModalOpen, setIsCancelModalOpen, confirmAction} = props

    const titleText = props.titleText || 'Confirm cancel'
    const mainBodyText = props.mainBodyText || 'Are you sure you want to cancel? You’ll lose the work you’ve done so far.'
    const cancelButtonText = props.cancelButtonText || 'No, Go Back'
    const okButtonText = props.okButtonText || 'Yes, Cancel'

    return (<ModalTransition>
        {isCancelModalOpen && (
            <Modal width={'small'}>
                <ModalBody>
                    <Grid container className={classes.container} alignItems="flex-start">
                        <Grid item xs={12}>
                            <Box className={classes.crossIcon}
                                 onClick={props.closeAction}>
                                <CrossIcon label="close" size="medium"/>
                            </Box>
                            <Typography className={classes.cancelTitle}>
                                {titleText}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.cancelBody}>
                                {mainBodyText}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.actionsRow} spacing={1} direction="row"
                          justifyContent='flex-end'>
                        <Grid item>
                            <Button appearance="default" className={classes.cancelButton}
                                    onClick={props.cancelAction}>
                                {cancelButtonText}
                            </Button>
                            <Button appearance="primary" onClick={() => {
                                setIsCancelModalOpen(false)
                                confirmAction()
                            }}>
                                {okButtonText}
                            </Button>
                        </Grid>
                    </Grid>
                </ModalBody>
            </Modal>
        )}
    </ModalTransition>)
}

export default CancelModal