import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
        viewTimeLogButton: {
            textAlign: 'end',
            flexGrow: 1,
            marginTop: 34,
            '@media (min-width:773px) and (max-width:959px)': {
                marginTop: 0
            },
        },
        downloadReportButton: {
            flex: '0 0 182px',
            '@media (min-width:959px) and (max-width:1007px)': {
                marginTop: 0,
                flex: 'auto'
            },
            '@media (max-width:771px)': {
                flex: 'auto',
            }
        }
    }
)

export { useStyles }
