import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles({
        container: {
            padding: '22px 0px',
        },
        actionsRow: {
            marginTop: 15,
            marginBottom: 22
        },
        cancelButton: {
            marginRight: 8
        },
        cancelTitle: {
            fontSize: 20,
            fontFamily: 'SF Pro Display Semibold',
            lineHeight: '24px',
            color: '#172B4D',
            letterSpacing: 0
        },
        crossIcon: {
            float: 'right'
        },
        cancelBody: {
            marginTop: 16,
            fontFamily: 'SF UI Text Regular',
            fontSize: 14,
            lineHeight: '20px'
        }
    }
)

export {useStyles}
