import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles({
        container: {
            padding: 24
        },
        tableContainer: {
            '& > div': {
                maxHeight: 150,
                overflowY: 'auto'
            }
        },
        tableTitle: {
            color: '#172B4D',
            fontSize: 14,
            lineHeight: '20px',
            fontFamily: 'SF Pro Text Semibold',
            letterSpacing: 0,
            marginBottom: 15
        },
        noWorklogsMessage: {
            fontFamily: 'SF Pro Display Regular',
            fontSize: 14,
            color: '#172B4D',
            lineHeight: '20px',
            width: 280
        },
        tooltip: {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap'
        }
    }
)

export {useStyles}
