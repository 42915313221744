import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
    datePickerContainer: {
        textAlign: 'start',
        minWidth: 140
    },
})

export { useStyles }
