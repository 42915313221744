import {ReportData} from '../types/ReportProperties'
import Member from 'trello-shared-resources/dist/types/Member'
import {getDateByTimezone, getMemberById} from './Persistence'
import {WorkLogData, WorkLogStored} from '../types/LogProperties'
import Card from 'trello-shared-resources/dist/types/Card'

/**
 * Convert the given workLogsStored array into an ReportData array, filling member and card
 * @param workLogsStored data from AWS
 * @param members array of board members
 * @param otherMembers array of members that maybe they are not board members
 * @param setState method to update otherMembers
 * @param licenseDetails
 * @param cards card array to fill the reportData
 * @return a promise that will return ReportData[]
 */
export async function getTimeLogEntries(workLogsStored: WorkLogStored[], members: Member[], otherMembers: Member[], setState: any, licenseDetails: any, cards: Card[]): Promise<ReportData[]> {
    const timeLogEntries: ReportData[] = []

    for (let i = 0; i < workLogsStored.length; i++) {
        const workLogStored = workLogsStored[i]
        const member = await findMemberById(workLogStored.memberId, members, otherMembers, setState, licenseDetails)
        let card: any
        if (cards && workLogStored.cardId) card = cards.find((card: Card) => card.id === workLogStored.cardId)
        const startDate = workLogStored.date === 0 && workLogStored.startDate ? workLogStored.startDate : workLogStored.date
        timeLogEntries.push({
            date: getDateByTimezone(startDate, workLogStored.userTimezone),
            rawStartDate: startDate,
            endDate: workLogStored.endDate ? getDateByTimezone(workLogStored.endDate, workLogStored.userTimezone) : undefined,
            rawEndDate: workLogStored.endDate,
            startTime: workLogStored.startTime,
            endTime: workLogStored.endTime,
            member: member,
            loggedHours: workLogStored.hoursSpent || 0,
            card: card,
            key: workLogStored.hashKey,
            workDescription: workLogStored.workDescription,
            userTimezone: workLogStored.userTimezone,
            listId: workLogStored.listId,
            billable: workLogStored.billable
        } as ReportData)
    }
    return timeLogEntries
}

/**
 * Convert the given workLogsStored array into an WorkLogData array
 * @param workLogsStored data from AWS
 * @param members array of board members
 * @param otherMembers array of members that maybe they are not board members
 * @param setState method to update otherMembers
 * @param licenseDetails
 * @return a promise that will return WorkLogData[]
 */
export async function getWorklogDataEntries(workLogsStored: WorkLogStored[], members: Member[], otherMembers: Member[], setState: any, licenseDetails: any): Promise<WorkLogData[]> {
    const logEntries: WorkLogData[] = []
    for (let i = 0; i < workLogsStored.length; i++) {
        const workLogStored = workLogsStored[i]
        const member = await findMemberById(workLogStored.memberId, members, otherMembers, setState, licenseDetails)
        logEntries.push({
            date: getDateByTimezone(workLogStored.date, workLogStored.userTimezone),
            memberFullName: member ? member.fullName : 'Unknown user',
            memberName: member ? member.username : '',
            hoursLogged: workLogStored.hoursSpent || 0,
            cardId: workLogStored.cardId,
            key: workLogStored.hashKey,
            workDescription: workLogStored.workDescription
        } as WorkLogData)
    }
    return logEntries
}

/**
 * Find a member by the given memberId inside members and otherMembers array
 * @param memberId
 * @param members array of board members
 * @param otherMembers array of members that maybe they are not board members
 * @param setState method to update otherMembers
 * @param licenseDetails
 * @return a promise that will contain a Member or undefined
 */
async function findMemberById(memberId: string, members: Member[], otherMembers: Member[], setState: any, licenseDetails: any): Promise<Member | undefined> {
    let member = members.find((member: Member) => member.id === memberId)
    if (!member) {
        member = otherMembers.find((member: Member) => member.id === memberId)
        if (!member) {
            member = await getMemberById(licenseDetails, memberId)
            if (member) {
                otherMembers.push(member)
                setState(otherMembers)
            }
        }
    }
    return member
}