import React from 'react'
import CancelModal from './CancelModal'
import {RemoveModalProperties} from '../../types/RemoveModalProperties'
import {removeTimeEntry} from '../../modules/Persistence'
import {supportUrl, TrackActionEvent} from 'trello-shared-resources/dist'
import {MessageType} from '../../error/FetchDataError'
import {Link} from '@material-ui/core'
import {canCurrentUserPerformActions} from '../../modules/Utils'

const RemoveModal = (props: RemoveModalProperties) => {

    const {
        licenseDetails,
        isRemovedModalOpen,
        setIsRemovedModalOpen,
        setWorklogsIsLoading,
        incrementActionPerformed,
        setReportDataMessage,
        worklog,
        currentMember,
        titleText,
        mainBodyText,
        cancelButtonText,
        okButtonText
    } = props

    /**
     * Method to remove a worklog
     */
    const removeConfirmAction = () => {
        setWorklogsIsLoading(true)
        const trelloIframeContext = licenseDetails.trelloIframeContext
        if (worklog && canCurrentUserPerformActions(worklog, currentMember)) {
            removeTimeEntry(trelloIframeContext, worklog.key, currentMember).then(() => {
                const trelloContextInfo = trelloIframeContext.getContext()
                TrackActionEvent('Log Work', trelloContextInfo, {
                    board_id: trelloContextInfo.board,
                    card_id: worklog.card?.id || '',
                    time: worklog.loggedHours,
                    action: 'remove',
                    description: worklog.workDescription
                })
                incrementActionPerformed()
                setReportDataMessage({
                    title: '',
                    subTitle: <>
                        You have successfully deleted the time log.
                    </>,
                    messageType: MessageType.success,
                    closable: true
                })
            }).catch(() => {
                setWorklogsIsLoading(false)
                setReportDataMessage({
                    title: '',
                    subTitle: <>
                        Sorry! The time log was not deleted. Please try again. If the issue persists, please contact our
                        support team <Link href={supportUrl}
                                           target="_blank">here</Link>.
                    </>,
                    messageType: MessageType.error,
                    closable: true
                })
            })
        } else {
            setWorklogsIsLoading(false)
            setReportDataMessage({
                title: '',
                subTitle: 'You can not remove time logs that belongs to other users.',
                messageType: MessageType.error,
                closable: true
            })
        }

    }

    return (
        <CancelModal isCancelModalOpen={isRemovedModalOpen} setIsCancelModalOpen={setIsRemovedModalOpen}
                     titleText={titleText}
                     mainBodyText={mainBodyText}
                     cancelButtonText={cancelButtonText}
                     okButtonText={okButtonText}
                     cancelAction={() => setIsRemovedModalOpen(false)}
                     closeAction={() => setIsRemovedModalOpen(false)}
                     confirmAction={removeConfirmAction}/>
    )
}

export default RemoveModal
