import ReportDataGroup from './ReportDataGroup'
import { LogEntry, ReportData } from '../../types/ReportProperties'
import _ from 'lodash'

/**
 * Groups the reports by Member.
 */
class ReportDataGroupByMember implements ReportDataGroup {

    group(data: Array<ReportData>): LogEntry[] {
        const groupedData = _.groupBy(data, (element) => element.member?.id)

        const reportData = Object.keys(groupedData)
            .map((key) => this.marshallToGroupEntityBannerObject(key, groupedData[key]))

        return reportData
    }

    private marshallToGroupEntityBannerObject(key: string, data: Array<ReportData>) {
        let noOfHoursWorked = 0
        let noOfBillableHoursWorked = 0
        let noOfDescriptions = 0
        const cardsIds: any = []
        data.forEach((element) => {
            noOfHoursWorked += element.loggedHours
            if(element.billable) noOfBillableHoursWorked += element.loggedHours
            noOfDescriptions += element.workDescription ? 1 : 0
            if (element.card) {
                cardsIds.push(element.card.id)
            }
        })

        const memberName = `${data[0].member?.fullName} (${data[0].member?.username})`
        const uniq = Array.from(new Set(cardsIds))

        return {
            headerKey: key,
            headerValue: memberName,
            noOfWorkLogs: data.length,
            noOfHoursWorked,
            noOfBillableHoursWorked,
            noOfDescriptions,
            noOfDistinctCards: uniq.length,
            entityType: 'member',
            tableData: data
        } as LogEntry
    }
}

export default ReportDataGroupByMember