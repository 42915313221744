import ExportModel from '../../models/ExportModel'
import { ReportData } from '../../types/ReportProperties'
import { labelsTransformer } from './Transformers'
import {convertSecondsToTimeDuration, convertSecondsToWorklogDuration} from '../Utils'

class ExportModelAdaptor implements ExportModel {
    Start_date: string
    End_date: string | undefined
    Start_time: string
    End_time: string
    Log_name: string | undefined
    Total_time: string
    Total_time_mins: number
    Work_description: string | undefined
    Log_username: string | undefined
    Card_name: string | undefined
    Card_shortlink: string | undefined
    Card_labels: string | undefined
    Billable: string

    constructor(data: ReportData) {
        this.Start_date = data.date
        this.End_date = data.endDate
        this.Start_time = convertSecondsToTimeDuration(data.startTime)
        this.End_time = convertSecondsToTimeDuration(data.endTime)
        this.Log_name = data.member?.fullName
        this.Total_time = convertSecondsToWorklogDuration(data.loggedHours)
        this.Total_time_mins = Math.floor(data.loggedHours / 60)
        this.Work_description = data.workDescription
        this.Log_username = data.member?.username

        this.Card_name = data.card?.name
        this.Card_shortlink = data.card?.shortUrl

        this.Card_labels = labelsTransformer(data.card?.labels)
        this.Billable = data.billable ? 'Yes' : 'No'
    }
}

export default ExportModelAdaptor