import React, {useState} from 'react'
import Button from '@atlaskit/button'
import {SecondaryButtonProperties} from './SecondaryButtonProperties'

const SecondaryButton = (props: SecondaryButtonProperties) => {
    const [hover, setHover] = useState(false)

    return (
        <Button
            ref={(el) => {
                const color = hover ? '#0052CC' : '#42526E'
                if (el) {
                    el.style.setProperty('color', color, 'important')
                    el.style.backgroundColor = 'white'
                    el.style.setProperty('border', color + ' solid 1px', 'important')
                }
            }}
            onClick={props.onClick}
            onMouseEnter={() => {
                setHover(true)
            }}
            onMouseLeave={() => {
                setHover(false)
            }}
        >Log Time</Button>
    )
}

export default SecondaryButton