import {ReportData} from '../../types/ReportProperties'
import Board from 'trello-shared-resources/dist/types/Board'
import ExportModelAdaptor from './ExportModelAdaptor'
import SaveFile from 'trello-shared-resources/dist/modules/file/SaveFile'

import XLSX from 'xlsx'
import moment from 'moment'
import ExportModel from '../../models/ExportModel'

class MSExcelDownload {
    fileType: string

    constructor() {
        this.fileType = 'application/octet-stream'
    }

    download(data: Array<ReportData>, board:Board) {
        const exportData = data.map((dataItem: ReportData) => new ExportModelAdaptor(dataItem))
        const excelOutput = this.createXlsxFromData(exportData)
        const saveFile = new SaveFile(this.fileType)
        const currentDate = moment().format('MM/DD/YYYY')
        const fileName = `${board.name} ${currentDate}.xlsx`
        saveFile.saveDataToFile(fileName, excelOutput)
    }

    private createXlsxFromData(exportData: Array<ExportModel>) {
        // Create the Worksheet and Workbook
        const sheet = XLSX.utils.json_to_sheet(exportData)
        const book = XLSX.utils.book_new()

        // Append the new Worksheet to the Workbook
        XLSX.utils.book_append_sheet(book, sheet, "Export")

        // Write the workbook out to 16 bit character String
        const output16Bit = XLSX.write(book, {
            bookType: 'xlsx',
            type: 'binary'
        })

        return this.stringTo8BitBuffer(output16Bit)
    }

    /**
     * Specific to the XLSX library, we need to convert the file content (as a String w/ 16 bit characters) to an ArrayBuffer 
     * with 8 bit characters.
     * 
     * @param {*} rawFileContent The content of the XLSX just after it has been created.
     */
    private stringTo8BitBuffer(rawFileContent: any) {
        const output = new ArrayBuffer(rawFileContent.length)
        const view = new Uint8Array(output)
        for (let index = 0; index !== rawFileContent.length; ++index) {
            view[index] = rawFileContent.charCodeAt(index) & 0xFF
        }
        return output
    }
}

export default MSExcelDownload