import {createTheme} from "react-data-table-component"
import {makeStyles} from "@material-ui/core/styles";


createTheme('atlasKitTheme', {
    text: {
        primary: '#172B4D',
        secondary: '#172B4D',
    },
    background: {
        default: '#f4f5f7',
    },
    divider: {
        default: '#f4f5f7',
    },
    highlightOnHover: {
        default: '#dde0e4',
    }
}, 'light')

export const groupDataTableCustomStyles = {
    headRow: {
        style: {
            fontFamily: 'SF Pro Display Bold',
            borderBottom: '1px solid'
        },
    },
    headCells: {
        style: {
            paddingLeft: '8px',
            paddingRight: '8px'
        }
    },
    cells: {
        style: {
            paddingLeft: '8px',
            paddingRight: '8px'
        }
    },
    expanderCell: {
        style: {
            paddingLeft: '8px',
            paddingRight: '8px'
        }
    },
    tableWrapper: {
        style: {
            display: 'block',
            '@media (max-width:1030px)': {
                width: '111vh'
            }
        }
    },
    pagination: {
        style: {
            justifyContent: 'center'
        }
    }
}

const useStyles = makeStyles({
    noDataRow: {
        padding: '50px 53px 50px 53px',
        color: '#172B4D',
        fontFamily: "SF Pro Text Regular",
        fontSize: 14,
        letterSpacing: 0,
        lineHeight: '20px',
        textAlign: 'center'
    },
})

export { useStyles }