import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles({
        resultMessageContainer: {
            marginTop: 8
        },
        closeResultMessageContainer: {
            float: "right",
        }
    }
)

export {useStyles}