import React, {useEffect, useState} from "react"
import DynamicTable from "@atlaskit/dynamic-table"
import {Box, Grid, Typography} from "@material-ui/core"
import {useStyles} from "./WorklogTableStyles"
import Button from "@atlaskit/button"
import TrashIcon from "@atlaskit/icon/glyph/trash"
import EditIcon from "@atlaskit/icon/glyph/edit"
import {canCurrentUserPerformActions, convertSecondsToWorklogDuration} from "../../modules/Utils"
import Member from "trello-shared-resources/dist/types/Member"
import {getCurrentMember} from "../../modules/Persistence"
import {WorklogTableProps} from "../../types/WorklogTableProps"
import {ReportData} from "../../types/ReportProperties"
import {SortOrderType} from "@atlaskit/dynamic-table/types"
import Modal, {ModalBody, ModalTransition} from "@atlaskit/modal-dialog"
import LogWorkTime from "./LogWorkTime"
import {MessageType} from "../../error/FetchDataError"
import Tooltip from "@atlaskit/tooltip"
import RemoveModal from '../common/RemoveModal'
import CheckCircleIcon from '@atlaskit/icon/glyph/check-circle'
import CrossCircleIcon from '@atlaskit/icon/glyph/cross-circle'


export const WorklogTable = (props: WorklogTableProps) => {

    const classes = useStyles()

    const {
        licenseDetails,
        worklogsIsLoading,
        setWorklogsIsLoading,
        incrementActionPerformed,
        setReportDataMessage,
        createdOrEditedRow,
        setCreatedOrEditedRow
    } = props
    const [currentMember, setCurrentMember] = useState<Member | undefined>()
    const [orderBy, setOrderBy] = useState<{ key: string, sortOrder: SortOrderType }>({key: 'date', sortOrder: 'DESC'})
    const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false)
    const [isRemovedModalOpen, setIsRemovedModalOpen] = useState<boolean>(false)
    const [worklog, setWorklog] = useState<ReportData | undefined>(undefined)
    const [pageNumber, setPageNumber] = useState<number>(1)

    useEffect(() => {
        if (!currentMember) {
            getCurrentMember(licenseDetails.trelloIframeContext).then((member) => {
                setCurrentMember(member)
            })
        }
    }, [licenseDetails.trelloIframeContext, setCurrentMember, currentMember, pageNumber, createdOrEditedRow])


    /**
     * Handler to confirm removing worklogs
     * @param worklog to remove
     */
    const removeWorklogHandler = (worklog: ReportData) => {
        if (!canCurrentUserPerformActions(worklog, currentMember)) {
            setReportDataMessage({
                title: '',
                subTitle: 'You can not remove time logs that belongs to other users.',
                messageType: MessageType.error,
                closable: true
            })
            return
        }
        setWorklog(worklog)
        setIsRemovedModalOpen(true)
    }

    const headers = {
        cells: [
            {
                key: 'date',
                content: 'Date',
                isSortable: true,
                width: 100
            },
            {
                key: 'member',
                content: 'Member',
                isSortable: true,
                width: 200
            },
            {
                key: 'loggedTime',
                content: 'Logged Time',
                isSortable: true,
                width: 100
            },
            {
                key: 'description',
                content: 'Description',
                isSortable: true,
                width: 200
            },
            {
                key: 'billable',
                content: 'Billable',
                isSortable: false,
                width: 55
            },
            {
                key: 'delete',
                content: 'Delete',
                width: 50
            },
            {
                key: 'edit',
                content: 'Edit',
                width: 50
            },
        ],
    }

    /**
     * Sort worklogs by orderBy info from status
     * @param row1
     * @param row2
     * @return number that reflects the difference between row1 and row2 data
     */
    const sortWorklogs = (row1: ReportData, row2: ReportData) => {
        switch (orderBy.key) {
            case 'member':
                if (orderBy.sortOrder === 'ASC')
                    return (row1.member?.fullName || '') > (row2.member?.fullName || '') ? -1 : 1
                else
                    return (row2.member?.fullName || '') >= (row1.member?.fullName || '') ? -1 : 1
            case 'loggedTime':
                return orderBy.sortOrder === 'ASC' ? (row1.loggedHours || 0) - (row2.loggedHours || 0) : (row2.loggedHours || 0) - (row1.loggedHours || 0)
            case 'description':
                if (orderBy.sortOrder === 'ASC')
                    return (row1.workDescription || '') > (row2.workDescription || '') ? -1 : 1
                else
                    return (row2.workDescription || '') >= (row1.workDescription || '') ? -1 : 1
            case 'date':
            default:
                return orderBy.sortOrder === 'ASC' ? (row1.rawStartDate || 0) - (row2.rawStartDate || 0) : (row2.rawStartDate || 0) - (row1.rawStartDate || 0)
        }
    }

    const editWorklogHandler = (worklog: ReportData) => {
        if (!canCurrentUserPerformActions(worklog, currentMember)) {
            props.setReportDataMessage({
                title: '',
                subTitle: 'You can not edit time logs that belongs to other users.',
                messageType: MessageType.error,
                closable: true
            })
            return
        }
        setWorklog(worklog)
        setIsEditModalOpen(true)
    }

    const worklogRows = props.worklogs
        .sort(sortWorklogs)
        .map((worklog: ReportData) => ({
            key: worklog.key,
            cells: [
                {
                    key: 'date',
                    content: worklog.date,
                },
                {
                    key: 'member',
                    content: <Tooltip content={worklog.member?.fullName || ''} data-tag="allowRowEvents">
                        <Box data-tag="allowRowEvents" className={classes.tooltip}>
                            {worklog.member?.fullName || ''}
                        </Box>
                    </Tooltip>
                },
                {
                    key: 'loggedTime',
                    content: convertSecondsToWorklogDuration(worklog.loggedHours),
                },
                {
                    key: 'description',
                    content: <Tooltip content={worklog.workDescription} data-tag="allowRowEvents">
                        <Box data-tag="allowRowEvents" className={classes.tooltip}>
                            {worklog.workDescription}
                        </Box>
                    </Tooltip>
                },
                {
                    key: 'billable',
                    content: worklog.billable ?
                        <CheckCircleIcon label='Billable' primaryColor={'#00875A'}/> :
                        <CrossCircleIcon label='No billable' primaryColor={'#DE350B'}/>,
                },
                {
                    content: <Button
                        appearance="subtle"
                        onClick={() => removeWorklogHandler(worklog)}
                        isDisabled={!canCurrentUserPerformActions(worklog, currentMember)}
                        iconBefore={<TrashIcon primaryColor={'#42526E'} label="Delete"/>}
                    >
                    </Button>
                },
                {
                    content: <Button
                        isDisabled={!canCurrentUserPerformActions(worklog, currentMember)}
                        appearance="subtle" onClick={() => editWorklogHandler(worklog)}
                        iconBefore={<EditIcon primaryColor={'#42526E'} label="Edit"/>}
                    >
                    </Button>
                }
            ]
        }))

    const calculateEditedWorklogIndexToHighlight = () => {
        if (!createdOrEditedRow) return -1
        const rowIndex = worklogRows.findIndex(row => row.key === createdOrEditedRow)
        const newPageNumber = Math.trunc(rowIndex / 5) + 1
        if(pageNumber !== newPageNumber) setPageNumber(newPageNumber)
        return [rowIndex % 5]
    }

    return (<>
    <Grid container className={classes.container} alignItems="flex-start">
        <Grid item xs={12}>
            <Typography className={classes.tableTitle}>Time Logs</Typography>
        </Grid>
        <Grid item xs={12} className={worklogRows && worklogRows.length > 0 ? classes.tableContainer : ''}>
            <DynamicTable
                head={headers}
                rows={worklogRows}
                rowsPerPage={5}
                defaultPage={1}
                isFixedSize
                page={pageNumber}
                onSetPage={(newPageNumber: number) => {
                    setCreatedOrEditedRow(undefined)
                    setPageNumber(newPageNumber)
                }}
                highlightedRowIndex={calculateEditedWorklogIndexToHighlight()}
                defaultSortKey="date"
                defaultSortOrder="DESC"
                loadingSpinnerSize='small'
                isLoading={worklogsIsLoading}
                sortKey={orderBy.key}
                sortOrder={orderBy.sortOrder}
                onSort={(data: any) => setOrderBy(data)}
                emptyView={
                    <Typography className={classes.noWorklogsMessage}>
                        There are no time logs entered for this card.
                        Log time using the fields above.
                    </Typography>}
            />
            <ModalTransition>
                {isEditModalOpen && worklog && (
                    <Modal width={'large'}>
                        <ModalBody>
                            <LogWorkTime {...props} setIsWorklogModalOpen={setIsEditModalOpen} worklog={worklog}
                                         setCreatedOrEditedRow={setCreatedOrEditedRow} editWorklogFromCard={true}/>
                        </ModalBody>
                    </Modal>
                )}
            </ModalTransition>
        </Grid>
    </Grid>
    <RemoveModal isRemovedModalOpen={isRemovedModalOpen}
                     setIsRemovedModalOpen={setIsRemovedModalOpen}
                     licenseDetails={licenseDetails}
                     worklog={worklog}
                     currentMember={currentMember}
                     incrementActionPerformed={incrementActionPerformed}
                     setReportDataMessage={setReportDataMessage}
                     setWorklogsIsLoading={setWorklogsIsLoading}
                     titleText={'Confirm Delete'}
                     mainBodyText={'Are you sure?'}
                     cancelButtonText={'Cancel'}
                     okButtonText={'OK'}/>
    </>)
}