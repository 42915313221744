import {makeStyles} from '@material-ui/core/styles'
import {CSSObject} from '@emotion/core'
import {ThemeProps, ThemeTokens} from '@atlaskit/button'


const useStyles = makeStyles({
        startTimerButton:{
            margin: 5,
        },
        timerContainer: {
            flexGrow: 1
        },
        timerButtonGroupContainer: {
            '& > button, & > button:hover': {
                paddingRight: 3,
                paddingLeft: 3,
                borderLeft: '1px solid #FFFFFF',
                borderRadius: 0,
                margin: '6px 0',
                height: 20
            },
            '& > button > span:first-child': {
                // borderRadius: 3
                lineHeight: '20px'
            },
            height: 32,
            backgroundColor: '#5243AA',
            borderRadius: 3,
            marginTop: 5
        },
        counterContainer: {
            backgroundColor: '#5243AA',
            width: 'fit-content',
            height: 20,
            float: 'left',
            color: '#FFFFFF',
            fontFamily: "SF Pro Text Bold",
            fontSize: 14,
            letterSpacing: 0,
            lineHeight: '32px',
            borderRadius: 3,
            padding: '0 3px'
        },
        spinnerContainer: {
            marginTop: 10
        }
    }
)

const timerButtonThemeConfig = {
    default: {
        background: {
            default: { light: '#5243AA' },
            hover: { light: '#5243AA' },
            active: { light: '#5243AA' },
            disabled: { light: '#5243AA' },
        },
        color: {
            default: { light: '#FFFFFF' },
            hover: { light: '#FFFFFF' },
            active: { light: '#FFFFFF' },
            disabled: { light: '#FFFFFF' },
        },
    },
}

function timerButtonTheme(
    currentTheme: (props: ThemeProps) => ThemeTokens,
    themeProps: ThemeProps
): ThemeTokens {
    const {buttonStyles, ...rest} = currentTheme(themeProps)
    return {
        buttonStyles: {
            ...buttonStyles,
            ...extract(themeProps)
        },
        ...rest
    }
}

function extract({
                     mode,
                     /* istanbul ignore next */
                     appearance = 'default',
                     state
                 }: ThemeProps): CSSObject | undefined {
    // @ts-ignore
    const root = timerButtonThemeConfig[appearance]

    /* istanbul ignore next */
    if (!root) return undefined

    return Object.keys(root).reduce((acc: { [index: string]: string }, val) => {
        let node = root;
        [val, state, mode].forEach((item) => {
            /* istanbul ignore next */
            if (!item || !node[item]) {
                /* istanbul ignore next */
                return undefined
            }
            if (typeof node[item] !== 'object') {
                acc[val] = node[item]
                return undefined
            }
            node = node[item]
            return undefined
        })
        return acc
    }, {})
}

export { useStyles, timerButtonTheme }
