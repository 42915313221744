import ReportDataGroup from './ReportDataGroup'
import { LogEntry, ReportData } from '../../types/ReportProperties'
import _ from 'lodash'

/**
 * Groups the retrieved time log entries by
 */
class ReportDataGroupByCard implements ReportDataGroup {

    group(data: Array<ReportData>): LogEntry[] {
        const groupedData = _.groupBy(data, (element) => element.card?.id)

        const reportData = Object.keys(groupedData)
            .map((key) => this.marshallToGroupEntityBannerObject(key, groupedData[key]))

        return reportData
    }

    private marshallToGroupEntityBannerObject(key: string, data: Array<ReportData>) {
        let noOfHoursWorked = 0
        let noOfBillableHoursWorked = 0
        let noOfDescriptions = 0
        const membersIds: any = []
        data.forEach((element) => {
            noOfHoursWorked += element.loggedHours
            if(element.billable) noOfBillableHoursWorked += element.loggedHours
            noOfDescriptions += element.workDescription ? 1 : 0
            if (element.member) {
                membersIds.push(element.member.id)
            }
        })
        const uniq = Array.from(new Set(membersIds))
        return {
            headerKey: key,
            headerValue: data[0].card?.name,
            noOfWorkLogs: data.length,
            noOfHoursWorked,
            noOfDescriptions,
            noOfBillableHoursWorked,
            noOfDistinctMembers: uniq.length,
            entityType: 'card',
            tableData: data
        } as LogEntry
    }
}

export default ReportDataGroupByCard