export function customerErrorMapping(errorMessage: string) {
    const adapter = errorMapping.find((mapping) => mapping(errorMessage))

    if (!adapter) {
        return errorMessage
    }

    return adapter(errorMessage)
} 

const errorMapping = [
    (errorMessage: string) => {
        if (errorMessage.startsWith('PluginData length of 4096 characters exceeded.')) return 'Unfortunately you\'ve reached the maximum number of time entries that are able to be stored on this card.'
    }
]