import React, {PropsWithChildren, useEffect, useState} from 'react'
import DataTable, {createTheme} from 'react-data-table-component'
import {
    canCurrentUserPerformActions,
    convertSecondsToTimeDuration,
    convertSecondsToWorklogDuration
} from '../../../modules/Utils'
import {Box} from '@material-ui/core'
import TrashIcon from '@atlaskit/icon/glyph/trash'
import EditIcon from '@atlaskit/icon/glyph/edit'
import Button from '@atlaskit/button'
import TableCellTooltip from './TableCellTooltip'
import {NestedDataTableProperties, ReportData} from '../../../types/ReportProperties'
import Modal, {ModalBody, ModalTransition} from '@atlaskit/modal-dialog'
import LogWorkTime from '../../log/LogWorkTime'
import {getCurrentMember} from '../../../modules/Persistence'
import Member from 'trello-shared-resources/dist/types/Member'
import RemoveModal from '../../common/RemoveModal'
import {MessageType} from '../../../error/FetchDataError'

/**
 * Nested rows in Report Table where data is shown
 */
const NestedDataTable: React.FC<NestedDataTableProperties> = (props: PropsWithChildren<NestedDataTableProperties>) => {

    const {
        data, licenseDetails, setIsLoading, incrementActionPerformed, parentWidth,
        setReportDataMessage
    } = props

    const isCardType = data.entityType === 'card'
    const [isRemoveModalOpen, setIsRemoveModalOpen] = useState<boolean>(false)
    const [currentMember, setCurrentMember] = useState<Member | undefined>()

    useEffect(() => {
        const trelloIframeContext = licenseDetails.trelloIframeContext
        getCurrentMember(trelloIframeContext).then((member) => {
            setCurrentMember(member)
        })
    }, [licenseDetails.trelloIframeContext, setCurrentMember])

    const [isEditModalOpen, setIsEditModalOpen] = useState(false)
    const [worklog, setWorklog] = useState<ReportData | undefined>(undefined)

    const getWorklogByRowKey = (rowKey: any) => {
        return props.downloadData?.find((reportData: ReportData) => reportData.key === rowKey)
    }

    /**
     * Handler to confirm removing worklogs
     * @param idWorklog to remove
     */
    const removeWorklogHandler = (idWorklog: any) => {
        const worklogRow = getWorklogByRowKey(idWorklog)
        if (worklogRow && !canCurrentUserPerformActions(worklogRow, currentMember)) {
            setReportDataMessage({
                title: '',
                subTitle: 'You can not remove time logs that belongs to other users.',
                messageType: MessageType.error,
                closable: true
            })
            return
        }
        setIsRemoveModalOpen(true)
        setWorklog(worklogRow)
    }

    createTheme('nestedTheme', {
        text: {
            primary: '#172B4D',
            secondary: '#172B4D',
        },
        background: {
            default: '#FFFFFF',
        },
        divider: {
            default: '#FFFFFF',
        },
        highlightOnHover: {
            default: '#dde0e4',
        },
    }, 'light')

    const columns = [
        {
            selector: () => '',
            width: '48px',
        },
        {
            selector: (row: any) => '',
            maxWidth: `${Math.max((parentWidth - 148) / 6, 100)}px`

        },
        {
            selector: (row: any) => isCardType ? row.member.fullName : row.card.name,
            cell: (row: any) => <TableCellTooltip cellValue={isCardType ? row.member.fullName : row.card.name}
                                                  tooltip={isCardType ? `${row.member.fullName} (${row.member.username})` : row.card.name}/>,
            maxWidth: `${Math.max((parentWidth - 148) / 6, 100)}px`,
            style: {
                paddingLeft: parentWidth > 745 && data.tableData.length > 10 ? 24 : 8
            }
        },
        {
            selector: (row: any) => row.date,
            cell: (row: any) => <TableCellTooltip cellValue={`${row.date}...`}
                                                  tooltip={row.endDate ? `${row.date} ${convertSecondsToTimeDuration(row.startTime)} - ${row.endDate} ${convertSecondsToTimeDuration(row.endTime)}` : row.date}/>,
            maxWidth: `${Math.max((parentWidth - 148) / 6, 100)}px`,
            style: {
                paddingLeft: parentWidth > 745 && data.tableData.length > 10 ? 24 : 8
            }
        },
        {
            selector: (row: any) => convertSecondsToWorklogDuration(row.loggedHours),
            maxWidth: `${Math.max((parentWidth - 148) / 6, 100)}px`,
            style: {
                paddingLeft: parentWidth > 745 && data.tableData.length > 10 ? 24 : 8
            }
        },
        {
            selector: (row: any) => row.workDescription,
            cell: (row: any) => <TableCellTooltip cellValue={row.workDescription}/>,
            maxWidth: `${Math.max((parentWidth - 148) / 6, 100)}px`,
            style: {
                paddingLeft: parentWidth > 745 && data.tableData.length > 10 ? 24 : 8
            }
        },
        {
            selector: (row: any) => row.billable ? convertSecondsToWorklogDuration(row.loggedHours) : '0h 0m',
            maxWidth: `${Math.max((parentWidth - 148) / 6, 100)}px`,
            style: {
                paddingLeft: parentWidth > 745 && data.tableData.length > 10 ? 24 : 8
            }
        },
        {
            width: '100px',
            cell: (row: any) => {
                return (
                    <>
                        <Button
                            appearance="subtle"
                            onClick={() => removeWorklogHandler(row.key)}
                            isDisabled={!canCurrentUserPerformActions(row, currentMember)}
                            iconBefore={<TrashIcon primaryColor={'#42526E'} label="Delete"/>}
                        >
                        </Button>
                        <Button id={row.key}
                                isDisabled={!canCurrentUserPerformActions(row, currentMember)}
                                appearance="subtle" onClick={() => {
                            setWorklog(getWorklogByRowKey(row.key))
                            setIsEditModalOpen(true)
                        }}
                                iconBefore={<EditIcon primaryColor={'#42526E'} label="Edit"/>}
                        >
                        </Button>
                    </>
                )
            },
            style: {
                paddingLeft: parentWidth > 745 && data.tableData.length > 10 ? 23 : 8
            }
        },
    ]

    const customStyles = {
        cells: {
            style: {
                padding: '0px 8px',
                minWidth: '0 !important'
            }
        },
    }

    // change the background color of the selected row to light blue when it is edited
    const conditionalRowStyles = [
        {
            when: (row: ReportData) => row.key === props.editedRow,
            style: {
                backgroundColor: '#ddecff'
            },
        }
    ]

    return (
        <Box sx={{maxHeight: 500, overflow: 'auto'}}>
            <DataTable
                columns={columns}
                noTableHead={true}
                highlightOnHover={true}
                theme="nestedTheme"
                data={data.tableData}
                conditionalRowStyles={conditionalRowStyles}
                customStyles={customStyles}
            />
            <ModalTransition>
                {isEditModalOpen && worklog && (
                    <Modal width={'large'}>
                        <ModalBody>
                            <LogWorkTime {...props} setIsWorklogModalOpen={setIsEditModalOpen} worklog={worklog}/>
                        </ModalBody>
                    </Modal>
                )}
            </ModalTransition>
            <RemoveModal isRemovedModalOpen={isRemoveModalOpen}
                         setIsRemovedModalOpen={setIsRemoveModalOpen}
                         licenseDetails={licenseDetails}
                         worklog={worklog}
                         currentMember={currentMember}
                         incrementActionPerformed={incrementActionPerformed}
                         setReportDataMessage={setReportDataMessage}
                         setWorklogsIsLoading={setIsLoading}
                         titleText={'Confirm Delete'}
                         mainBodyText={'Delete this time log?'}
                         cancelButtonText={'Cancel'}
                         okButtonText={'OK'}/>
        </Box>
    )
}

export default NestedDataTable